
import hasModel from '@/mixins/hasModel'
import { mapMutations, mapState } from 'vuex'
import hasRegistry from '~/mixins/hasRegistry'

export default {
    mixins: [hasModel, hasRegistry],

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'guides.json')
    },

    computed: {
        ...mapState({
            guideList: (state) => state.jsonloader.data['guides.json'] || null,
        }),

        cover() {
            return this.guide?.src ? this.guide.src : null
        },

        readingTime() {
            return this.guide && this.guide.metas && this.guide.metas.reading_time_seconds
                ? Math.floor(this.guide.metas.reading_time_seconds / 60).toFixed(0)
                : undefined
        },

        guide() {
            return this.items?.length ? this.items.find((el) => el.id === this.model.id) || null : null
        },

        items() {
            return this.guideList?.items || []
        },
    },

    methods: {
        ...mapMutations({
            triggerProductModal: 'products/triggerProductModal',
        }),

        goToGuide() {
            if (!this.$auth.loggedIn) {
                this.triggerProductModal(this.getRegistry('i_do_crew.product_id'))
                return
            }
            this.$router.push('/guides/' + this.guide.slug)
        },
    },
}

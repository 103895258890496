export default {
    props: {
        // eslint-disable-next-line vue/require-default-prop
        dataFetchState: {
            type: null,
        },
    },

    computed: {
        isLoading() {
            return this.dataFetchState && this.dataFetchState.pending
        },
    },
}

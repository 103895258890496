export default {
    methods: {
        queryParams(url, obj = {}) {
            const query = Object.keys(obj)
                .filter((k) => obj[k] !== undefined)
                .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
                .join('&')
            return !url.includes('?') ? `${url}?${query}` : `${url}&${query}`
        },
    },
}

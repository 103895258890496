
import hasModel from '@/mixins/hasModel'
import capitalize from '@/mixins/capitalize'

export default {
    mixins: [hasModel, capitalize],

    props: {
        routeName: {
            type: String,
            default: '',
        },
        preventLink: Boolean,
    },

    computed: {
        to() {
            return this.preventLink
                ? { name: this.$route.name, params: this.$route.params, query: this.$route.query }
                : `${this.routeName}/?tags=${this.model.slug}`
        },
    },
}

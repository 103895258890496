
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },

        max: {
            type: Number,
            default: 100,
        },

        colors: {
            type: Array,
            default: () => {
                return []
            },
        },

        border: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        progressbarStyle() {
            return {
                width: `${((this.value / this.max) * 100).toFixed(0)}%`,
                backgroundColor: this.colors[1],
            }
        },
    },
}

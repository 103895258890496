import { render, staticRenderFns } from "./AppearanceItem.vue?vue&type=template&id=21d913b8&lang=pug"
import script from "./AppearanceItem.vue?vue&type=script&lang=js"
export * from "./AppearanceItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyCard: require('/app/components/elements/containers/Card.vue').default})

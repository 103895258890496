
import hasModel from '@/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasModel, hasContentMeta],

    props: {
        hideSaveButton: Boolean,
        last: Boolean,
        mini: Boolean,
        collection: {
            type: Array,
            default: () => [],
        },

        parentData: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    content_type: 'wedding-inspiration',
                    userBoard: undefined,
                }
            },
        },
    },
    computed: {
        link() {
            if (this.parentData.id && this.parentData.username)
                return {
                    name: 'image-viewer-username-parent-id',
                    params: { id: this.model.id, parent: this.parentData.id, username: this.parentData.username },
                }
            else if (this.parentData.id) return { name: 'image-viewer-parent-id', params: { id: this.model.id, parent: this.parentData.id } }
            else return { name: 'image-viewer-id', params: { id: this.model.id } }
        },
    },

    mounted() {
        this.$on('loaded', () => {
            if (this.last) {
                this.$store.dispatch('data/setAllItemsLoaded', true)
            }
        })
    },
}

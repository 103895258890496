
import { mapState, mapGetters } from 'vuex'
import hasModel from '@/mixins/hasModel'
import hasContentMeta from '@/mixins/hasContentMeta'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'

export default {
    mixins: [hasModel, hasContentMeta, impression, handleRouterLinkClick],

    props: {
        to: {
            type: null,
            default: null,
        },

        alt: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        apiUrl() {
            return process.env.apiUrl
        },

        courseLink() {
            if (this.$auth.loggedIn && this.isPremiumUser) {
                const urlArray = this.model.url.split('/')
                return {
                    name: 'users-username-courses-slug',
                    params: { username: this.user.username, slug: urlArray[2] },
                }
            } else {
                return `/tools${this.model.url}`
            }
        },

        progress() {
            if (this.user.courses && this.user.courses.length > 0) {
                const userCourseData = this.user.courses.find((el) => {
                    return el.id === this.model.id
                })
                return userCourseData?.lessons_percent.toFixed(2) * 100 || 0
            } else {
                return null
            }
        },

        courseButtonText() {
            let text
            switch (this.progress) {
                case 0:
                    text = 'Start Course'
                    break
                case 100:
                    text = 'View Course'
                    break

                default:
                    text = 'Continue Course'
            }

            if (!this.isPremiumUser) {
                text = 'Preview Course'
            }

            return text
        },
    },
}


import debounce from '@/mixins/debounce'
import { mapState } from 'vuex'

export default {
    props: {
        gap: {
            type: Number,
            default: 20,
        },
        columnWidth: {
            type: Number,
            // default: 420,
        },

        columns: {
            type: Number,
        },

        mobileOverride: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            id: null,
            observer: null,
            animationInterval: null,
        }
    },

    computed: {
        ...mapState({ allItemsLoaded: (state) => state.data.allItemsLoaded || false }),
    },

    watch: {
        allItemsLoaded(val) {
            if (val) {
                this.$nextTick(() => {
                    this.debounceResizeGridItems()
                    this.setContentWidth()
                    this.$store.dispatch('data/setAllItemsLoaded', false)
                })
            }
        },
    },

    mounted() {
        this.setupObserver()
        this.id = 'masonry-grid-' + this._uid

        if (process.browser) {
            window.addEventListener('resize', this.debounceResizeGridItems)
            window.addEventListener('resize', this.setContentWidth)
            if (window.innerWidth < 576 && this.mobileOverride) {
                this.$el.style.setProperty('--gap-x', `10px`)
            } else {
                this.$el.style.setProperty('--gap-x', `${this.gap}px`)
                this.$el.style.setProperty('--gap-y', `${this.gap / 2}px`)
            }
            this.setContentWidth()
        }
    },

    beforeDestroy() {
        clearInterval(this.animationInterval)
        if (this.observer) this.observer.disconnect()
    },

    destroyed() {
        if (process.browser) {
            window.removeEventListener('resize', this.debounceResizeGridItems)
            window.removeEventListener('resize', this.setContentWidth)
        }
    },

    methods: {
        setContentWidth() {
            const columnWidth =
                window.innerWidth < 576
                    ? this.mobileOverride
                        ? `repeat(2, 1fr)`
                        : `stretch`
                    : this.columns
                    ? `repeat(${this.columns}, 1fr)`
                    : `repeat(auto-fill, minmax(${this.columnWidth}px, 1fr))`

            this.$el.style.setProperty('--template-columns', columnWidth)
        },

        setupObserver() {
            this.observer = new MutationObserver((mutations) => {
                this.$nextTick(() => {
                    this.$forceUpdate()
                    this.debounceResizeGridItems()
                })
            })
            this.observer.observe(this.$el.parentNode, {
                attributes: false,
                childList: true,
                characterData: true,
                subtree: true,
            })
        },

        resizeGridItem(item) {
            const grid = this.$refs.masonryGrid
            if (grid) {
                const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'))
                const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'))
                const itemChild = item.querySelector('* > *')
                let rowSpan = null
                if (itemChild) {
                    const height = itemChild.getBoundingClientRect().height
                    rowSpan = Math.ceil((height + rowGap) / (rowHeight + rowGap))
                }
                item.style.gridRowEnd = 'span ' + rowSpan
            }
        },

        debounceResizeGridItems: debounce(function () {
            this.resizeAllGridItems()
        }, 100),

        resizeAllGridItems() {
            const allItems = document.querySelectorAll(`#${this.id} .masonry-grid-brick`)
            for (let i = 0; i < allItems.length; i++) {
                this.resizeGridItem(allItems[i])
            }
            this.animateItems(allItems)
        },

        animateItems(allItems) {
            clearInterval(this.animationInterval)
            let counter = 0
            this.animationInterval = setInterval(() => {
                if (allItems[counter]) allItems[counter].style.opacity = 1
                counter++
                if (!allItems[counter]) clearInterval(this.animationInterval)
            }, 10)
        },
        hiddenPromotion(content) {
            return content.componentOptions?.tag === 'loverly-idc-promotion' && content.componentInstance?.model?.id && content.componentInstance?.isPremiumUser
        },
    },

    render(h) {
        const content = this.$slots.default
            ? this.$slots.default.map(
                  function (content, index) {
                      return h(
                          'div',
                          {
                              class: [
                                  'masonry-grid-brick d-flex transition-opacity duration-150',
                                  `masonry-grid-brick-${this._uid + index}`,
                                  'opacity-0',
                                  content && content.tag && !this.hiddenPromotion(content) ? '' : 'hidden',
                                  content && content.data && content.data.class === 'hidden' ? 'hidden' : '',
                              ],
                              key: 'masonry-grid-brick-' + this._uid + index,
                          },
                          [content]
                      )
                  }.bind(this)
              )
            : []
        return h(
            'div',
            {
                class: ['masonry-grid', 'mx-auto', 'grid'],
                ref: 'masonryGrid',
                attrs: {
                    id: this.id,
                },
            },
            content
        )
    },
}

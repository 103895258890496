import debounce from './debounce'
export default {
    methods: {
        setValue: debounce(function (val, key, show = false) {
            this.debouncedSetValue(val, key, show)
        }, 500),

        debouncedSetValue(val, key, show = false) {
            if (this.internalValue) this.$set(this.internalValue, key, val)
            const query = {}

            const value =
                val && Array.isArray(val)
                    ? val.map((el) => {
                          return el.slug
                      })
                    : val.slug
                    ? val.slug
                    : val

            query[key === 'search' ? 'q' : key] = value

            this.$router.push({
                query: {
                    ...this.$route.query,
                    ...query,
                    page: 1,
                },
            })
        },
    },
}

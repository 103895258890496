import { render, staticRenderFns } from "./GuideList.vue?vue&type=template&id=d6a3ba04&lang=pug"
import script from "./GuideList.vue?vue&type=script&lang=js"
export * from "./GuideList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyGuideItem: require('/app/components/pages/guides/containers/GuideItem.vue').default})

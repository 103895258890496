export default {
    props: {
        user: {
            type: null,
            default: () => {
                return {}
            },
        },
    },
}

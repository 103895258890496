
export default {
    props: {
        to: {
            type: null,
            default: null,
        },
        href: {
            type: String,
            default: '',
        },
    },

    render(h) {
        return h(
            this.to ? 'NuxtLink' : 'div',
            {
                class: 'card relative transition-colors no-underline block',
                props: {
                    to: this.to,
                },
            },
            [this.$slots.default]
        )
    },
}


import hasModel from '@/mixins/hasModel'
import hasContentMeta from '@/mixins/hasContentMeta'
export default {
    mixins: [hasModel, hasContentMeta],

    props: {
        align: {
            type: String,
            default: 'start',
        },
        imageHeight: {
            type: Number,
            default: 0,
        },
        avatar: {
            type: Object,
            default: () => {
                return {}
            },
        },
        hideSaveButton: Boolean,

        maxSize: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            isDdOpen: false,
        }
    },

    computed: {
        src() {
            if (this.avatar?.url) return this.avatar.url
            return this.model?.cover
                ? this.maxSize?.width
                    ? `${this.apiUrl}/${this.maxSize.width}/${this.maxSize.height}/${this.model.cover.id}`
                    : this.model.cover.url
                : this.mappedImages?.length
                ? this.mappedImages[0]
                : this.model.perkImage || '/emptyspace_realweddings.png'
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        mappedImages() {
            return this.images
                .map((el) =>
                    el.cover ? (this.maxSize?.width ? `${this.apiUrl}/${this.maxSize.width}/${this.maxSize.height}/${el.cover.id}` : el.cover.url) : undefined
                )
                .filter((el) => el)
        },

        alt() {
            return this.model ? this.model.name : 'Image'
        },
    },

    mounted() {
        if (process.browser && this.imageHeight) this.$el.style.setProperty('--image-height', `${this.imageHeight}px`)

        this.$refs.image?.$on('loaded', () => {
            this.$emit('loaded')
        })
    },
}

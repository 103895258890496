
import hasModel from '@/mixins/hasModel'
import download from 'js-file-download'
import { mapMutations } from 'vuex'
import hasRegistry from '~/mixins/hasRegistry'

export default {
    mixins: [hasModel, hasRegistry],

    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        cover() {
            return this.model?.image ? this.model.image : this.model?.cover?.url ? this.model.cover.url : '/emptyspace_realweddings.png'
        },

        fileType() {
            const fileType = this.model?.file?.mime.split('/')
            return fileType?.length ? fileType[fileType.length - 1] : 'document'
        },

        fileSize() {
            return this.model?.file?.size ? parseInt(`${this.model.file.size}`) : this.model?.size ? parseInt(`${this.model.size}`) : 0
        },

        fileSizeFormatted() {
            let e = (Math.log2(this.fileSize) / 10) | 0
            return `${(this.fileSize / 1024 ** (e = e <= 0 ? 0 : e)).toFixed(1)} ${['B', 'kB', 'MB'][e]}`
        },

        url() {
            return this.model?.file?.url ? this.model.file.url : this.model?.id ? `/api/v1/exclusive_files/${this.model.id}?download=true` : undefined
        },
    },

    watch: {
        model: {
            handler() {
                if (this.model.id)
                    this.$trackEvent('IMPRESSION', {
                        content_type: 'download-item',
                        name: this.model.name,
                        id: this.model.id,
                    })
            },
            immediate: true,
        },
    },

    methods: {
        ...mapMutations({
            triggerProductModal: 'products/triggerProductModal',
        }),

        download() {
            if (!this.$auth.loggedIn) {
                this.$toast.info('Locked content, login to access').goAway(3000)
                this.triggerProductModal(this.getRegistry('i_do_crew.product_id'))
                return
            }
            if (!this.disabled && this.url) {
                this.$axios
                    .get(this.url, { responseType: 'arraybuffer' })
                    .then((res) => {
                        this.$trackEvent('DOWNLOAD', {
                            name: this.model.name,
                            content_type: 'download-item',
                            id: this.model.id,
                        })

                        download(res.data, `${this.model.slug}.pdf`)
                    })
                    .catch((e) => {
                        if (e.response.status === 402) {
                            this.$toast.error('You dont have permission to see this document').goAway(3000)
                        }
                    })
            }
        },
    },
}

export default {
    methods: {
        handleOutBoundClick(url, model) {
            this.$trackEvent('OUTBOUND_CLICK', {
                target_url: url,
                name: model.name,
                content_type: model.content_type.slug,
                id: model.id,
            })
        },
    },
}


import hasModel from '@/mixins/hasModel'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'

export default {
    mixins: [hasModel, handleRouterLinkClick],

    props: {
        alt: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        gaEvent() {
            return {
                name: 'author-view',
                data: { method: 'Google', event_category: 'engagement', event_label: 'authors', value: 0 },
            }
        },

        computedModel() {
            return {
                ...this.model,
                content_type: {
                    slug: 'author',
                },
            }
        },
    },

    watch: {
        model: {
            handler() {
                if (this.model.id)
                    this.$trackEvent('IMPRESSION', {
                        content_type: 'author',
                        name: this.model.name,
                        id: this.model.id,
                    })
            },
            immediate: true,
        },
    },
}


import hasModel from '@/mixins/hasModel'
export default {
    mixins: [hasModel],

    computed: {
        src() {
            return this.model?.cover ? this.model.cover.url : '/emptyspace_realweddings.png'
        },

        metas() {
            return this.model?.metas || {}
        },

        link() {
            return this.metas.source_url
        },
    },

    watch: {
        model: {
            handler() {
                if (this.model.id)
                    this.$trackEvent('IMPRESSION', {
                        content_type: 'appearance-item',
                        name: this.model.name,
                        id: this.model.id,
                        referal_url: this.link,
                    })
            },
            immediate: true,
        },
    },

    methods: {
        handleClick() {
            this.$trackEvent('OUTBOUND_CLICK', {
                target_url: this.link,
                name: this.model.name,
                content_type: 'appearance-item',
                id: this.model.id,
            })
        },
    },
}


import hasUser from '@/mixins/user/hasUser'

export default {
    mixins: [hasUser],

    computed: {
        computedSocial() {
            const obj = { ...this.user.social }
            Object.keys(this.user.social).map((el) => {
                if (!this.user.social[el]) this.$delete(obj, el)
            })
            return obj
        },
    },

    methods: {
        getFormattedSocialLink(site, val) {
            switch (site) {
                case 'twitter':
                    return val.includes('http') ? val : `https://twitter.com/${val}`
                case 'instagram':
                    return val.includes('http') ? val : `https://instagram.com/${val}`
                case 'linkedin':
                    return val.includes('http') ? val : `https://linkedin.com/in/${val}`
                case 'facebook':
                    return val.includes('http') ? val : `https://www.facebook.com/${val}`
                default:
                    return `${val}`
            }
        },

        getSocialIcon(site) {
            switch (site) {
                case 'website':
                    return 'globe'

                default:
                    return site
            }
        },

        handleClick(url, name) {
            this.$trackEvent('OUTBOUND_CLICK', {
                target_url: url,
                name,
                content_type: 'social',
            })
        },
    },
}

import { render, staticRenderFns } from "./CardImage.vue?vue&type=template&id=632c6675&lang=pug"
import script from "./CardImage.vue?vue&type=script&lang=js"
export * from "./CardImage.vue?vue&type=script&lang=js"
import style0 from "./CardImage.vue?vue&type=style&index=0&id=632c6675&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default})

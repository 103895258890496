
import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasModel, hasContentMeta, impression, handleRouterLinkClick],

    props: {
        hideSaveButton: Boolean,
        preventLink: Boolean,
        forcedHover: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rowsHeight: 0,
            isDdOpen: false,
        }
    },

    computed: {
        photographer() {
            if (this.photographers?.length) return this.photographers.find((el) => true)
            return null
        },

        link() {
            return this.preventLink ? { name: this.$route.name, params: this.$route.params, query: this.$route.query } : '/real-weddings/' + this.model.slug
        },

        location() {
            return this.model?.metas?.location
        },

        src() {
            if (this.avatar?.url) return this.avatar.url
            return this.model?.cover
                ? `${this.apiUrl}/500/500/${this.model.cover.id}`
                : this.mappedImages?.length
                ? this.mappedImages[0]
                : this.model.perkImage || '/emptyspace_realweddings.png'
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        mappedImages() {
            return this.images
                .map((el) => {
                    return el.cover ? `${this.apiUrl}/500/500/${el.cover.id}` : undefined
                })
                .filter((el) => el)
        },
        hasMappedImages() {
            return !!this.mappedImages?.length
        },

        additionalInfo() {
            const photographer = this.photographer?.slug ? this.photographer.name : ''
            const location = this.location || ''

            return photographer || location
                ? `${photographer}${location && photographer ? ' <span class="font-bold mx-1">&middot;</span> ' : ''}${location}`
                : ''
        },

        gaEvent() {
            return {
                name: 'view_item',
                data: { method: 'Google', event_category: 'engagement', event_label: 'real-weddings', value: 0 },
            }
        },
    },

    mounted() {
        if (process.browser) window.addEventListener('resize', this.getRowsHeight)

        const rowsInterval = setInterval(() => {
            if (this.$refs?.mainImage) {
                this.getRowsHeight()
                clearInterval(rowsInterval)
            }
        }, 100)
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.getRowsHeight)
    },

    methods: {
        getRowsHeight() {
            if (this.$refs?.mainImage) this.rowsHeight = this.$refs.mainImage.clientWidth + 'px'
        },

        openInNewTab() {
            if (process.browser) window.open(this.link)
        },
    },
}


import { mapState } from 'vuex'

export default {
    props: {
        perPage: {
            type: Number,
            default: 0,
        },
    },

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'guides.json')
    },

    computed: {
        ...mapState({
            guideList: (state) => state.jsonloader.data['guides.json'] || null,
        }),

        items() {
            return this.guideList?.items || []
        },

        showItems() {
            if (!this.perPage) return this.items
            return this.items.slice(0, this.perPage)
        },
    },
}

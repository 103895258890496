
import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'

export default {
    mixins: [hasModel, impression, handleRouterLinkClick],

    props: {
        category: {
            type: Object,
            default: () => {
                return {}
            },
        },
        to: {
            type: null,
            default: null,
        },
        bigger: Boolean,
        hideImage: Boolean,

        forcedHover: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        apiUrl() {
            return process.env.apiUrl
        },

        isPlanning() {
            return this.model.content_type.url.match(/\/planning.+/)
        },

        taxonomy() {
            return this.category?.name
                ? this.category.name
                : this.model?.taxonomy?.length && !this.isPlanning
                ? this.model.taxonomy
                      .filter((el) => !el.is_hidden)
                      .map((el) => el.name)
                      .join(' ')
                : this.model.content_type?.name || ''
        },

        link() {
            return this.to ? this.to : `${this.model.url}`
        },

        gaEvent() {
            return {
                name: 'view_item',
                data: { method: 'Google', event_category: 'engagement', event_label: 'planning-card', value: 0 },
            }
        },
    },
}


import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasModel, hasContentMeta, impression, handleRouterLinkClick],

    props: {
        preventLink: Boolean,
    },

    data() {
        return {
            rowsHeight: 0,
        }
    },

    computed: {
        link() {
            return this.preventLink ? { name: this.$route.name, params: this.$route.params, query: this.$route.query } : '/videos/' + this.model.slug
        },

        length() {
            return this.model?.metas.video_length
        },

        author() {
            return this.model?.user || {}
        },

        authorAvatar() {
            return this.user?.avatar || {}
        },

        src() {
            if (this.avatar?.url) return this.avatar.url
            return this.model?.cover
                ? `${this.apiUrl}/500/500/${this.model.cover.id}`
                : this.mappedImages?.length
                ? this.mappedImages[0]
                : this.model.perkImage || '/emptyspace_realweddings.png'
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        gaEvent() {
            return {
                name: 'view_item',
                data: { method: 'Google', event_category: 'engagement', event_label: 'videos', value: 0 },
            }
        },
    },

    mounted() {
        if (process.browser) window.addEventListener('resize', this.getRowsHeight)

        const rowsInterval = setInterval(() => {
            if (this.$refs?.mainImage) {
                this.getRowsHeight()
                clearInterval(rowsInterval)
            }
        }, 100)
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.getRowsHeight)
    },

    methods: {
        getRowsHeight() {
            if (this.$refs?.mainImage) this.rowsHeight = this.$refs.mainImage.clientWidth + 'px'
        },

        openInNewTab() {
            if (process.browser) window.open(this.link)
        },

        toMinutes(seconds) {
            const minutes = (parseInt(seconds) / 60).toFixed(0)
            const remainder = (parseInt(seconds) % 60).toFixed(0)
            return `${minutes}:${remainder < 10 ? '0' : ''}${remainder}`
        },
    },
}


import { mapState, mapGetters } from 'vuex'
import queryParams from '~/mixins/queryParams'

export default {
    mixins: [queryParams],

    props: {
        contentType: {
            type: String,
            default: '',
        },
        perPage: {
            type: Number,
            default: 10,
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: '',
        },
        featured: {
            type: Boolean,
        },
        container: {
            type: Boolean,
            default: false,
        },
        randomized: {
            type: Boolean,
        },

        columns: {
            type: Number,
            default: 3,
        },

        gap: {
            type: Number,
            default: 10,
        },

        extraQuery: {
            type: Object,
            default: () => {},
        },

        always: {
            type: Boolean,
            default: false,
        },

        to: {
            type: null,
            default: null,
        },

        hLevel: {
            type: Number,
            default: 3,
        },

        buttonClass: {
            type: String,
            default: 'btn-primary',
        },

        buttonMargin: {
            type: String,
            default: '',
        },

        order: {
            type: String,
            default: 'created_at-desc',
        },

        sectionClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            observer: null,
            contentList: [],
            id: null,
            loaded: false,
            loading: false,
        }
    },

    computed: {
        ...mapState({ taxonomies: (state) => state.data.taxonomies }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        list() {
            return this.taxonomies && this.taxonomies[this.contentType] ? this.taxonomies[this.contentType] : this.contentList
        },

        link() {
            return this.to || '/' + this.contentType
        },

        orderKey() {
            return this.order.split('-')[0] ?? 'created_at'
        },

        orderDir() {
            return this.order.split('-')[1] ?? 'desc'
        },
    },

    watch: {
        extraQuery() {
            this.fetch()
        },
    },

    mounted() {
        this.id = 'list-block-masonry-' + this._uid
        this.$nextTick(() => {
            if (this.observer) {
                this.observer.observe(this.$el)
            }
        })
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect()
            this.observer = null
        }
    },

    created() {
        if (process.browser)
            this.observer = new IntersectionObserver(this.observed, {
                root: null,
                rootMargin: '160px 0px 0px 0px',
                threshold: 0.75,
            })
    },

    methods: {
        observed(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting && !this.loaded && !this.loading) this.fetch()
            })
        },

        async fetch() {
            this.loading = true
            if (this.contentType === 'exclusive_files') {
                await this.$axios
                    .$get(
                        this.queryParams(`/api/v1/exclusive_files`, {
                            page: 1,
                            per_page: this.perPage,
                            status: 'published',
                        })
                    )
                    .then((res) => {
                        this.loaded = true
                        this.contentList = res.data
                    })
                    .catch(() => {
                        this.loaded = false
                        this.contentList = []
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else if (['users', 'authors', 'posses'].includes(this.contentType)) {
                await this.$axios
                    .$get(
                        this.queryParams(`/api/v1/public/users`, {
                            page: 1,
                            per_page: this.perPage,
                            status: this.contentType === 'users' ? 'all' : this.contentType,
                        })
                    )
                    .then((res) => {
                        this.loaded = true
                        this.contentList = res.data
                    })
                    .catch(() => {
                        this.loaded = false
                        this.contentList = []
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else if (
                [
                    'locations',
                    'wedding-inspiration',
                    'vendors',
                    'planning',
                    'real-weddings',
                    'appearances',
                    'products',
                    'shop',
                    'media',
                    'courses',
                    'videos',
                ].includes(this.contentType)
            )
                await this.$axios
                    .$get(
                        `${this.queryParams(`/api/v1/content/list`, {
                            content_type: this.contentType,
                            page: 1,
                            per_page: this.perPage,
                            is_randomized: this.randomized,
                            'meta-key': this.featured ? 'is_featured' : undefined,
                            'meta-val': this.featured ? 1 : undefined,
                            'order-by': this.featured ? 'publish_at' : this.orderKey,
                            'order-dir': this.featured ? 'desc' : this.orderDir,
                            ...this.extraQuery,
                        })}`
                    )
                    .then((res) => {
                        this.loaded = true
                        this.contentList = res.data
                    })
                    .catch(() => {
                        this.loaded = false
                        this.contentList = []
                    })
                    .finally(() => {
                        this.loading = false
                    })
            else this.loaded = true
            this.loading = false
        },
    },
}

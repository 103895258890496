import seQueryParam from '@/mixins/seQueryParam'
export default {
    mixins: [seQueryParam],

    props: {
        value: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            internalValue: {},
        }
    },

    watch: {
        internalValue: {
            handler() {
                if (this.internalValue) this.$emit('input', this.internalValue)
            },
            deep: true,
            immediate: false,
        },

        value: {
            handler() {
                if (this.value) this.internalValue = this.value
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        removeItem(item) {
            const value = this.value

            const queryParams = { ...this.$route.query }

            if (item.slug === 'search') {
                value.search = null
                this.$set(queryParams, 'q', undefined)
            } else {
                const keys = Object.keys(value)
                for (let i = 0; i < keys.length; i++) {
                    if (Array.isArray(value[keys[i]])) {
                        const idx = value[keys[i]].findIndex((el) => {
                            return el.slug === item.slug
                        })

                        if (idx !== -1) {
                            const values = value[keys[i]].filter((el, index) => {
                                return index !== idx
                            })
                            this.$set(value, keys[i], values.length ? values : null)

                            if (Array.isArray(queryParams[keys[i]]))
                                queryParams[keys[i]] = queryParams[keys[i]].filter((el, index) => {
                                    return index !== idx
                                })
                            else queryParams[keys[i]] = []

                            this.$set(queryParams, keys[i], queryParams[keys[i]].length ? [...queryParams[keys[i]]] : undefined)
                        }
                    } else if (value[keys[i]]) {
                        if (value[keys[i]].slug === item.slug) {
                            value[keys[i]] = null
                            this.$set(queryParams, keys[i], undefined)
                        }
                    }
                }
            }

            // queryParams.page = 1
            this.$router.push({ query: queryParams })

            this.$emit('input', value)
        },

        setFilters(list, key) {
            if (this.$route.query[key]) {
                this.$set(
                    this.internalValue,
                    key,
                    list.filter((el) => {
                        return Array.isArray(this.$route.query[key]) ? this.$route.query[key].includes(el.slug) : el.slug === this.$route.query[key]
                    })
                )
            }
        },
    },
}


import hasModel from '@/mixins/hasModel'
import filters from '@/components/elements/filters/filters'
import capitalize from '@/mixins/capitalize'
import isLoading from '@/mixins/isLoading'

export default {
    mixins: [hasModel, filters, capitalize, isLoading],

    props: {
        value: {
            type: Array,
            default: () => {
                return []
            },
        },
        removable: Boolean,
        small: Boolean,
    },

    computed: {
        isActive() {
            if (!this.internalValue || (this.internalValue && !Array.isArray(this.internalValue))) return false

            return (
                this.internalValue.findIndex((el) => {
                    return el.slug === this.model.slug
                }) !== -1
            )
        },
    },

    methods: {
        setTag() {
            if (this.isLoading) return
            if (this.internalValue) {
                const idx = this.internalValue.findIndex((el) => {
                    return el.slug === this.model.slug
                })

                if (idx !== -1 && this.removable) {
                    this.$emit('remove', this.model)
                    this.internalValue.splice(idx, 1)
                } else this.internalValue.push(this.model)
            } else {
                this.internalValue = [this.model]
            }
        },
    },
}
